(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

module.exports = _interopRequireDefault, module.exports.__esModule = true, module.exports["default"] = module.exports;
},{}],2:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _default = function _default(element) {
  $(element).slick();
};

exports.default = _default;

},{}],3:[function(require,module,exports){
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _slickInit = _interopRequireDefault(require("../../components/slick-init"));

// import InitPhoteWwipe from "../../components/phote-swipe";
// import FixedFilterPosition from "../../components/sidebar-position";
// import CopyToClipboard from "../../components/copy-to-clipboard";
function initSlick() {
  document.querySelectorAll("[data-slick]").forEach(function (element) {
    (0, _slickInit.default)(element);
  });
} // function enableTooltip() {
//   document.querySelectorAll("[data-toggle='tooltip']").forEach((element) => {
//     new bootstrap.Tooltip(element);
//   });
// }
// function fixedFilterPosition() {
//   document.querySelectorAll("[data-position='alignTop']").forEach((element) => {
//     new FixedFilterPosition(element);
//   });
// }
// function copyToClipboard() {
//   document.querySelectorAll("[data-action='copy']").forEach((element) => {
//     new CopyToClipboard(element);
//   });
// }


document.addEventListener("DOMContentLoaded", function () {
  initSlick(); // enableTooltip();
  // InitPhoteWwipe(".photoGallery");
  // fixedFilterPosition();
  // copyToClipboard();
});

},{"../../components/slick-init":2,"@babel/runtime/helpers/interopRequireDefault":1}]},{},[3]);
